<template lang="pug" src="./Events.pug">

</template>
<script>

import { mapActions } from 'vuex'
import { buildPayloadPagination } from '@/utils/utils.js'

import findIndex from 'lodash/findIndex'

  export default{
    name: 'Events',

    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      search: '',
      dataTableLoading: false,
      expanded: [],
      singleExpand: false,
      pagination: {
        sortBy: ['createdAt'],
        sortDesc: ['true']},
      fieldsToSearch: ['userName'],
      suspectDialog: false,
      unsuspectDialog: false,
      editingItem: {},
    }),

    computed: {
      headers() {
        return [
          { text: 'Criado', value: 'createdAt' },
          { text: 'Promotor(a)', value: 'userName' },
          { text: 'E-mail', value: 'userEmail' },
          { text: 'Link', value: 'slug', sortable: false },
          { text: 'Tipo', value: 'eventType' },
          { text: 'Plano', value: 'plan' },
          { text: 'Evento', value: 'actions', width: 150 }
        ]
      },
      events(){
        return this.$store.state.events.events
      },
      totalEvents(){
        return this.$store.state.events.totalEvents
      }
    },

    methods: {
      ...mapActions(['getEvents', 'adminLogin', 'updateEvent']),

      loginAsPromoter(eventId){
        console.log('eventId', eventId)
        this.adminLogin({ eventId: eventId })
      },

      openSuspectConfirmation(item){
        this.editingItem = item
        this.suspectDialog = true
      },

      openUnsuspectConfirmation(item){
        this.editingItem = item
        this.unsuspectDialog = true
      },

      async markAsSuspect(){
        await this.updateEvent({ _id: this.editingItem._id, suspect: true, advanceable: false })
        let index = findIndex(this.events, { '_id': this.editingItem._id })
        this.events[index].suspect = true

        this.suspectDialog = false
      },

      async markAsUnsuspect(){
        await this.updateEvent({ _id: this.editingItem._id, suspect: false })
        let index = findIndex(this.events, { '_id': this.editingItem._id })
        this.events[index].suspect = false
        this.unsuspectDialog = false
      },

      getPageFromUrl(){
        let url = new URL(location.href)
        let searchParams = new URLSearchParams(url.search)

        if(searchParams.get('page') == null){
          this.pagination.page = 1
          this.pagination.current = 1
        }else{
          this.pagination.page = Number(searchParams.get('page'))
          this.pagination.current = Number(searchParams.get('page'))
          this.pagination.itemsPerPage = Number(searchParams.get('itemsPerPage'))
        }
      },
      async getEventsData(){
        await this.getEvents({ query: '' })
      },
      getColor(item){
        if (item.plan == '1') return '#CCC'
        if (item.plan == '2') return '#96D4D1'
          else return '#60DCD6'
      },
      userData(item){
        this.$router.push({ path: `/promoter/${item.user[0]._id}` })
      },
      eventData(item){
        this.$router.push({ path: `/events/event/${item._id}` })
      },
      visitSite(slug){
        let href = 'https://cha.ifraldas.com.br/'+slug
        window.open(href, '_blank');
      },
      async searchByKey(){
        var searchQuery = ''
        if(this.search.length>0){ searchQuery = `?key=${this.search}` }
        await this.getEvents( { query: searchQuery } )
      },

      async doSearch() {
        try {
          this.dataTableLoading = true

          if(this.search.length>0){
            this.pagination.page = 1
            this.pagination.current = 1
          }

          await this.getEvents( buildPayloadPagination(this.pagination, this.buildSearch()) )

          //console.log(JSON.stringify(this.events,null,2))
          // var searchQuery = ''
          // if(this.search.length>0){ searchQuery = `?key=${this.search}` }
          // await this.getEvents( { query: searchQuery } )

          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch() {
        window.history.pushState('page', 'Title', `events?page=${this.pagination.page}&itemsPerPage=${this.pagination.itemsPerPage}&key=${this.search}`)
        return this.search
          ? `?key=${this.search}`
          : ''
      }
      // buildSearch() {
      //   console.log('Search: '+this.search
      //     ? { query: this.search, fields: this.fieldsToSearch.join(',') }
      //     : {})
      //   return this.search
      //     ? { query: this.search, fields: this.fieldsToSearch.join(',') }
      //     : {}
      // }
    },
    mounted(){

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const search = urlParams.get('key')
      if(search){ // se tiver busca na URL...
        this.search = search
        this.doSearch()
      }

      this.getPageFromUrl()
      this.doSearch()
      // carrega a a tabela na ultima página selecionada
      //console.log('this.pagination = '+JSON.stringify(this.pagination,null,2))
    },
    watch: {
      pagination: {
      async handler() {
        try {
          this.dataTableLoading = true

          await this.getEvents(
            buildPayloadPagination(this.pagination, this.buildSearch())
          )

          // salva na página atual na URL
          window.history.pushState('page', 'Title', `events?page=${this.pagination.page}&itemsPerPage=${this.pagination.itemsPerPage}&key=${this.search}`)
          // window.history.pushState('page', 'Title', `events?page=${this.pagination.page}&itemsPerPage=${this.pagination.itemsPerPage}`)

          this.dataTableLoading = false
          // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
        deep: true
      },

      // search() {
      //   clearTimeout(this.delayTimer)
      //   this.delayTimer = setTimeout(() => {
      //     this.doSearch()
      //   }, 400)
      // }
    }

  }
</script>
<style>

  /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
  tr.v-data-table__expanded__content{
    box-shadow: none !important;
    background-color: #F9F9F9;
  }

  table tbody .v-data-table__mobile-table-row{
    display: block !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content{
    display: block !important;
    width: 100% !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content td{
    display: table;
    width: 100% !important;
  }

  .custom-tooltip {
    opacity: 1!important;
  }

</style>
