<template lang="pug" src="./Partners.pug"></template>
<script>

import { buildPayloadPagination } from '@/utils/utils.js'
import { mapGetters, mapActions } from 'vuex'

  export default {

    data: () =>({
      searchStatus: ['waiting_payment'],
      fab: false,
      hover: true,
      dialog: false,
      selectedId: '',
      selectedStatus: '',
      selected: [],
      dataTableLoading: false,
      expanded: [],
      select: false,
      singleExpand: false,
      search: '',
      pagination: {
        sortBy: ['createdAt'],
        sortDesc: ['true']
      },

      fieldsToSearch: ['key', 'status'], // definido no código ('bankData.name' ou 'status')
      detailsDialog: false,
      withdrawId: null,
      itemCopied: false,
      lineBreak: '\n'
    }),

    computed: {
      ...mapGetters(['partnersWithdraws', 'partnersWithdrawsTotal', 'partnersWithdrawsReport', 'dash']),
      checkboxRule() {
        return [
          this.searchStatus.length > 0 || "Selecione pelo menos 1"
        ]
      },
      role() {
        return this.$store.state.auth.user.role
      },
      headers() {
        if(this.$store.state.auth.user.role == 'super'){
          return [
            { text: 'Solicitado em', value: 'createdAt' },
            { text: 'Realizado em', value: 'updatedAt' },
            { text: 'Nome', value: 'userName' },
            { text: 'Valor', value: 'value', sortable: false },
            { text: 'Status', value: 'status'},
            { text: 'Ações', value: 'actions' }
          ]
        } else {
          return [
            { text: 'Realizado em', value: 'updatedAt' },
            { text: 'Nome', value: 'userName' },
            { text: 'Valor', value: 'value', sortable: false },
            { text: 'Status', value: 'status'},
          ]
        }

      }
    },
    methods: {
      ...mapActions(['getPartnersWithdraws', 'updatePartnerWithdraw']),
      informWithdraw(costumer){
        // console.log(JSON.stringify(costumer,null,2))
        let firstName = costumer.event.userName.split(' ')[0]
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        let value = this.formatPrice(costumer.value)
        const text = `?text=Ol%C3%A1%20${firstName}!%20Tudo%20j%C3%B3ia%3F%0ASou%20a%20Marcella%20da%20iFraldas.%0ASeu%20resgate%20no%20valor%20de%20R%24${value}%20foi%20realizado%20com%20sucesso%20em%20${new Date(costumer.updatedAt).toLocaleDateString('pt-br')}.`

        let href = 'https://wa.me/55'+costumer.telefone.replace( /\D+/g, '')+text
        window.open(href, '_blank');
      },
      downloadNF(link){
        let href = link
        window.open(href, '_blank');
      },
      starkBankReceipt(link){
        let href = link
        window.open(href, '_blank');
      },

      async payWithStarkBank(eventId){
        await this.starkBankPay({ eventId: eventId })
      },

      loginAsPromoter(eventId){
        this.adminLogin({ eventId: eventId })
      },

      copyUserData(id){

        //document.getElementsByClassName("hiddenDiv").style.display = "block"
        document.getElementById(`div${id}`).style.display = "block"

        var copyText = document.getElementById(id)
        copyText.select()
        copyText.setSelectionRange(0, 99999) /* For mobile devices */
        document.execCommand("copy")

        document.getElementById(`div${id}`).style.display = "none"
        this.itemCopied = true
      },
      details(item){

        // console.log(item._id)

        this.withdrawId = item._id
        this.detailsDialog = true
      },
      visitSite(slug){
        let href = 'https://cha.ifraldas.com.br/'+slug
        window.open(href, '_blank');
      },
      goToEvent(id){
        //this.$router.push({ name: 'events-event', params: {activeTab: 2, eventId: id } })
        let routeData = this.$router.resolve({name: 'events-event', params: {activeTab: 2, eventId: id } })
        window.open(routeData.href, '_blank');
        //this.$router.push({ path: `/events/event/${id}`, params: {activeTab: 2 } }) // Não é é possível pre selecionar a aba usando o path, apenas com name
      },
      getColor(item){
        if (item.status == 'paid') return '#20A39E'
        if (item.status == 'waiting_payment') return '#FFBA49'
        if (item.status == 'fraud') return '#FD6A6E'
          else return '#CCC'
      },
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      informFraud(id, status){
        this.dialog = true
        this.selectedId = id
        if(status != 'fraud'){
          this.selectedStatus = 'fraud'
        }else{
          this.selectedStatus = 'paid'
        }
      },
      confirmChange(id, status){
        this.dialog = true
        this.selectedId = id
        this.selectedStatus = status
      },
      async checkOneWithdraw(){
        this.dialog = false
        var newStatus = this.selectedStatus

        var payload = {}

        payload = { withdraws: [ { _id: this.selectedId, status: newStatus } ] }

        await this.updatePartnerWithdraw(payload)

        this.doSearch()
      },
      async checkWithdraws(){
        this.dialog = false

        var obj = this.selected
        var withdraws = []
        Object.keys(obj).forEach(function(k){
          withdraws[k] = { _id: obj[k]._id, status: 'paid'}
        })

        var payload = { withdraws: withdraws }
        await this.updatePartnerWithdraw(payload)
        this.doSearch()

      },

      async doSearch() {

        try {
          this.dataTableLoading = true

          await this.getPartnersWithdraws(
            buildPayloadPagination(this.pagination, this.buildSearch())
          )
          this.dataTableLoading = false
          this.dash.notifications.commissionWithdraws = 0

        } catch (error) {
          this.dataTableLoading = false
        }

      },
      newStatusSearch(){
        if(this.searchStatus.length>0){
          this.pagination.page = 1
          this.doSearch()
        }
      },
      buildSearch() {

        return this.search
          ? { status: this.searchStatus.join(','), searchTerm: this.search } // alterado para o novo módo de pesquisa do KK - Não reutilizável
          : { status: this.searchStatus.join(',') }
      }
    },
    mounted() {
      // this.doSearch()
    },
    watch: {
      pagination: {
      async handler() {
        try {
          this.dataTableLoading = true

          await this.getPartnersWithdraws(
            buildPayloadPagination(this.pagination, this.buildSearch())
          )

          this.dataTableLoading = false
          this.dash.notifications.commissionWithdraws = 0

        } catch (error) {
          this.dataTableLoading = false
        }
      },
        deep: true
      }
    }

  }
</script>
<style>

    /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
    tr.v-data-table__expanded__content{
      box-shadow: none !important;
      background-color: #F9F9F9;
    }

    table tbody .v-data-table__mobile-table-row{
      display: block !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content{
      display: block !important;
      width: 100% !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content td{
      display: table;
      width: 100% !important;
    }

    .hiddenDiv{
      display: none;
    }

</style>
